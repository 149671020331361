import styled from '@emotion/styled';
import { colors, spacing } from '../../../utils/styleguide';
import mapData from './squareMap.json';
import Typography from '../text/Typography';
import { useLocations } from '../../../utils/hooks/useLocations';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getBoundingZoomBox, getPixelCoordinates, highlightLocations } from './mapUtils';
import { css } from '@emotion/react';
import { debounce } from 'lodash';

const MAP_HEIGHT = 450;

const MapWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.grey02};
  background: ${colors.grey50};
  max-height: ${MAP_HEIGHT}px;
  width: 100%;
  overflow: hidden;
  position: relative;
`;
const MapMaxSizeWrapper = styled.div`
  width: 100%;
  max-width: ${2 * MAP_HEIGHT}px;
`;

const Legend = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[0]}px;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: ${spacing[4]}px;
  background: linear-gradient(262deg, rgba(250, 250, 250, 0) 0.02%, #fafafa 100.02%);
`;

const LegendItem = styled.div<{ disabled?: boolean }>`
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
`;

const ColorBox = styled.div<{ color: string }>`
  display: inline-block;
  margin-right: ${spacing[1]}px;
  width: 8px;
  height: 8px;
  background: ${(props) => props.color};
`;

const indexColors = [
  colors.green300,
  colors.purple300,
  colors.orange300,
  colors.yellow300,
  colors.red300,
];

const getRectStyles = (mapColors?: string[]) => css`
  fill: ${colors.grey03};

  &.highlight-0 {
    fill: ${mapColors?.[0] ?? indexColors[0]};
  }

  &.highlight-1 {
    fill: ${mapColors?.[1] ?? indexColors[1]};
  }

  &.highlight-2 {
    fill: ${mapColors?.[2] ?? indexColors[2]};
  }

  &.highlight-3 {
    fill: ${mapColors?.[3] ?? indexColors[3]};
  }

  &.highlight-4 {
    fill: ${mapColors?.[4] ?? indexColors[4]};
  }

  &.disabled {
    opacity: 0.5;
  }
`;

const SVGStyles = css`
  width: 100%;
`;

type MapItem = {
  label?: string;
  locationCodes: string[];
  disabled?: boolean;
};

export default function SquareMap({
  items,
  mapColors,
}: {
  items: MapItem[];
  mapColors?: string[];
}) {
  const [currentViewbox, setCurrentViewbox] = useState<number[]>([0, 0, mapData.width, MAP_HEIGHT]);
  const svgRef = useRef<SVGSVGElement>(null);

  const allLocationCodes = items.flatMap((item) => item.locationCodes);
  const { data: locations } = useLocations(allLocationCodes);

  const updateViewbox = useCallback(() => {
    // Set the viewbox to fit all the highlighted pixels
    const allPixels = getPixelCoordinates([
      {
        locationCodes: items.flatMap((item) => item.locationCodes),
        locations,
      },
    ]);

    const { minX, maxX, minY, maxY } = allPixels.reduce(
      (bounding, pixel) => {
        return {
          minX: Math.min(bounding.minX, pixel.x),
          minY: Math.min(bounding.minY, pixel.y),
          maxX: Math.max(bounding.maxX, pixel.x),
          maxY: Math.max(bounding.maxY, pixel.y),
        };
      },
      {
        minX: Infinity,
        minY: Infinity,
        maxX: -Infinity,
        maxY: -Infinity,
      },
    );

    setCurrentViewbox(
      getBoundingZoomBox({
        minX,
        maxX,
        minY,
        maxY,
        zoomPadding: 10,
        minimumWidth: 800,
      }),
    );
  }, [items, locations]);

  useEffect(() => {
    if (!locations?.length) return;

    // Highlight the locations on the map
    items.forEach((item, index) => {
      const itemLocationCodes = item.locationCodes;
      const itemLocations = locations?.filter((location) =>
        itemLocationCodes.includes(location.code),
      );
      const className = `highlight-${index}`;
      const pixels = getPixelCoordinates([
        { locationCodes: itemLocationCodes, locations: itemLocations },
      ]);

      highlightLocations(pixels, mapData.boxSize, svgRef.current, className, item.disabled);
    });

    updateViewbox();
  }, [items, locations, updateViewbox]);

  useEffect(() => {
    const debouncedUpdateViewbox = debounce(updateViewbox, 300, {
      leading: true,
      trailing: true,
    });

    window.addEventListener('resize', debouncedUpdateViewbox);

    return () => window.removeEventListener('resize', debouncedUpdateViewbox);
  }, [updateViewbox]);

  const size = 6;
  const dotOffset = size / 2;

  return (
    <MapWrapper>
      <MapMaxSizeWrapper>
        <svg
          ref={svgRef}
          viewBox={`${currentViewbox}`}
          xmlns="http://www.w3.org/2000/svg"
          css={SVGStyles}
        >
          {mapData.svgRects.map((rect: { x: number; y: number }, index) => {
            return (
              <rect
                key={index}
                x={rect.x - dotOffset}
                y={rect.y - dotOffset}
                width={size}
                height={size}
                data-x={rect.x}
                data-y={rect.y}
                css={getRectStyles(mapColors)}
              />
            );
          })}
        </svg>
      </MapMaxSizeWrapper>

      <Legend>
        {items.map((item, index) => (
          <LegendItem key={index} disabled={item.disabled}>
            <Typography color={colors.blackSecondary} variant="overlineSmall">
              <ColorBox color={mapColors?.[index] ?? indexColors[index]} />
              {item.label}
            </Typography>
          </LegendItem>
        ))}
      </Legend>
    </MapWrapper>
  );
}
