import { GetLocationsResponse } from '../../../utils/api/location';

export type EntityLocation = {
  locationCodes?: string[];
  locations?: GetLocationsResponse['result'];
};

export function getPixelCoordinates(entities: EntityLocation[]) {
  const coordinates = [];

  for (const entity of entities) {
    for (const location of entity.locations ?? []) {
      if (location.subdivision) {
        const { lng = 0, lat = 0 } = location.subdivision;
        coordinates.push(lngLatToSquarePixel(lng, lat));
      } else if (location.country) {
        const { lng = 0, lat = 0 } = location.country;
        coordinates.push(lngLatToSquarePixel(lng, lat));
      }
    }
  }

  return coordinates;
}

function convertGeoToPixel({
  latitude,
  longitude,
  mapWidth,
  mapHeight,
  mapLngLeft,
  mapLngRight,
  mapLatBottom,
}: {
  latitude: number;
  longitude: number;
  mapWidth: number;
  mapHeight: number;
  mapLngLeft: number;
  mapLngRight: number;
  mapLatBottom: number;
}) {
  const mapLatBottomRad = (mapLatBottom * Math.PI) / 180;
  const latitudeRad = (latitude * Math.PI) / 180;
  const mapLngDelta = mapLngRight - mapLngLeft;

  const worldMapWidth = ((mapWidth / mapLngDelta) * 360) / (2 * Math.PI);
  const mapOffsetY =
    (worldMapWidth / 2) *
    Math.log((1 + Math.sin(mapLatBottomRad)) / (1 - Math.sin(mapLatBottomRad)));

  const x = (longitude - mapLngLeft) * (mapWidth / mapLngDelta);
  const y =
    mapHeight -
    ((worldMapWidth / 2) * Math.log((1 + Math.sin(latitudeRad)) / (1 - Math.sin(latitudeRad))) -
      mapOffsetY);

  return { x, y };
}

function lngLatToSquarePixel(lng: number, lat: number) {
  const width = 800;
  const height = 969;

  const { x, y } = convertGeoToPixel({
    latitude: lat,
    longitude: lng,
    mapWidth: width,
    mapHeight: height,
    mapLngLeft: -180,
    mapLngRight: 180,
    mapLatBottom: -88.98,
  });
  return { x, y };
}

export function highlightLocations(
  pixels: { x: number; y: number }[],
  boxSize: number,
  root: SVGSVGElement | ShadowRoot | null,
  className: string,
  disabled?: boolean,
) {
  if (!root) return;
  const rects = root.querySelectorAll(`.${className}`);
  for (const rect of Array.from(rects)) {
    rect.classList.remove(className);
    rect.classList.remove('disabled');
  }

  for (const pixel of pixels) {
    highlightPixel(pixel, boxSize, root, className, disabled);
  }
}

function highlightPixel(
  pixel: { x: number; y: number },
  boxSize: number,
  svgNode: SVGSVGElement | ShadowRoot,
  className: string,
  disabled?: boolean,
) {
  const { x, y } = pixel;

  const rx = Math.round(x / boxSize) * boxSize - boxSize / 2;
  const ry = Math.round(y / boxSize) * boxSize - boxSize / 2;

  const directions = [
    [0, 0],
    [boxSize, 0],
    [-boxSize, 0],
    [0, -boxSize],
    [0, boxSize],
  ];

  for (const direction of directions) {
    const x = rx + direction[0];
    const y = ry + direction[1];

    const dot = svgNode.querySelector(`[data-x='${x}'][data-y='${y}']`);
    if (dot) {
      dot.classList.add(className);
      if (disabled) {
        dot.classList.add('disabled');
      }
    }
  }
}

const countryMap = {
  // TODO: UPDATE THIS
  width: 800,
  height: 969,
};

export function getBoundingZoomBox({
  minX,
  maxX,
  minY,
  maxY,
  zoomPadding,
  minimumWidth,
}: {
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
  zoomPadding: number;
  minimumWidth: number;
}) {
  // Add padding to give some space
  minX -= zoomPadding;
  minY -= zoomPadding;
  maxX += zoomPadding;
  maxY += zoomPadding;
  const height = maxY - minY;
  const width = maxX - minX;

  // Adjust to ratio is 4:3
  if (width / height > 4 / 3) {
    const paddingNeeded = width / (4 / 3) - height;
    minY -= paddingNeeded / 2;
    maxY += paddingNeeded / 2;
  } else {
    const paddingNeeded = height * (4 / 3) - width;
    minX -= paddingNeeded / 2;
    maxX += paddingNeeded / 2;
  }

  // Make sure zoom is not too high
  if (maxX - minX < minimumWidth) {
    const diff = minimumWidth - (maxX - minX);
    minX -= diff / 2;
    maxX += diff / 2;
    minY -= ((3 / 4) * diff) / 2;
    maxY += ((3 / 4) * diff) / 2;
  }

  if (maxX > countryMap.width) {
    minX -= maxX - countryMap.width;
    maxX = countryMap.width;
  }

  if (maxY > countryMap.height) {
    minY -= maxY - countryMap.height;
    maxY = countryMap.height;
  }

  if (minX < 0) {
    maxX += -minX;
    minX = 0;
  }

  if (minY < 0) {
    maxY += -minY;
    minY = 0;
  }

  return [minX, minY, maxX - minX, maxY - minY];
}
